<template>
  <svg width="28" height="28" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.70406 19.9209L17.2041 33.4209C17.3085 33.5255 17.4326 33.6085 17.5692 33.6651C17.7058 33.7217 17.8522 33.7509 18 33.7509C18.1478 33.7509 18.2942 33.7217 18.4308 33.6651C18.5674 33.6085 18.6915 33.5255 18.7959 33.4209L32.2959 19.9209C32.4534 19.7636 32.5607 19.5631 32.6042 19.3447C32.6477 19.1264 32.6254 18.9 32.5402 18.6944C32.455 18.4887 32.3106 18.3129 32.1255 18.1893C31.9403 18.0657 31.7226 17.9998 31.5 18L25.875 18L25.875 6.75C25.875 6.15326 25.6379 5.58097 25.216 5.15901C24.794 4.73705 24.2217 4.5 23.625 4.5L12.375 4.5C11.7783 4.5 11.206 4.73706 10.784 5.15901C10.362 5.58097 10.125 6.15327 10.125 6.75L10.125 18L4.5 18C4.27736 17.9998 4.05969 18.0657 3.87452 18.1893C3.68935 18.3129 3.54502 18.4887 3.4598 18.6944C3.37458 18.9 3.3523 19.1264 3.39578 19.3447C3.43926 19.5631 3.54655 19.7636 3.70406 19.9209ZM11.25 20.25C11.5484 20.25 11.8345 20.1315 12.0455 19.9205C12.2565 19.7095 12.375 19.4234 12.375 19.125L12.375 6.75L23.625 6.75L23.625 19.125C23.625 19.4234 23.7435 19.7095 23.9545 19.9205C24.1655 20.1315 24.4516 20.25 24.75 20.25L28.7845 20.25L18 31.0345L7.21547 20.25L11.25 20.25Z" fill="white"/>
  </svg>

</template>

<script>
export default {
  name: "IconLevelDown"
}
</script>

<style scoped>

</style>