<template>
  <div class="difficulty">
    <div class="difficulty__title">
      <span>уровень сложности</span>
      <span v-text="currentDifficulty"/>
    </div>

    <div v-if="isUserCompleteMore5Sounds" class="difficulty__buttons">
      <button :disabled="!canDifficultyDown" @click="canDifficultyDown && $emit('down')">
        <IconLevelDown/>
      </button>
      <button :disabled="!canDifficultyUp" @click="canDifficultyUp && $emit('up')">
        <IconLevelUp/>
      </button>
    </div> 
  </div>
</template>


<script>
import IconLevelUp from '@/components/icons/IconLevelUp.vue';
import IconLevelDown from '@/components/icons/IconLevelDown.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'GameHeaderDifficulty',

  components: {
    IconLevelUp,
    IconLevelDown,
  },

  computed: {
    ...mapGetters([
      'currentDifficulty',
      'isUserCompleteMore5Sounds',
    ]),
    canDifficultyUp: ths => ths.currentDifficulty < 5,
    canDifficultyDown: ths => ths.currentDifficulty > 1,
  },
};
</script>


<style lang="scss" scoped>
.difficulty {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 60px;
  &__title {
    display: flex;
    flex-wrap: nowrap;
    font-weight: 700;
    justify-content: center;
    gap: 5px;
  }

  &__buttons {
    display: flex;
    justify-content: right;
    gap: 5px;
    & > button { 
      cursor: pointer;
      background-color: transparent;
      border: none;
      padding: 0;
      &[disabled] {
        cursor: not-allowed;
        opacity: .5;
      }
    }
  }
}
</style>