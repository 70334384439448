<template>
  <svg 
    fill="none" 
    :enable-background="`new 0 0 ${iconWidth} ${iconHeight}`" 
    :height="iconHeight" 
    :width="iconWidth" 
    :viewBox="`0 0 ${iconWidth} ${iconHeight}`"
    xmlns="http://www.w3.org/2000/svg"
    v-on="events"
  >
    <path d="M15 17H9" :stroke="localColor" :stroke-width="localColor" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15 20L9 20" :stroke="localColor" :stroke-width="localColor" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15.219 21.2481L16.2113 21.3721L15.219 21.2481ZM16.0005 14.9964L15.5237 14.1174L15.0719 14.3624L15.0082 14.8724L16.0005 14.9964ZM7.99955 14.9964L8.99183 14.8724L8.92808 14.3624L8.47633 14.1174L7.99955 14.9964ZM8.78101 21.2481L9.77329 21.124L9.77329 21.124L8.78101 21.2481ZM14.2267 21.124C14.1642 21.6245 13.7388 22 13.2344 22L13.2344 24C14.7474 24 16.0236 22.8734 16.2113 21.3721L14.2267 21.124ZM15.0082 14.8724L14.2267 21.124L16.2113 21.3721L16.9927 15.1204L15.0082 14.8724ZM19 8.5C19 10.8721 17.6261 12.977 15.5237 14.1174L16.4772 15.8754C19.1567 14.4221 21 11.6797 21 8.5L19 8.5ZM12 2C15.9278 2 19 4.96996 19 8.5L21 8.5C21 3.74577 16.9088 1.63711e-06 12 1.20797e-06L12 2ZM5 8.5C5 4.96996 8.07223 2 12 2L12 1.20797e-06C7.09121 7.78827e-07 3 3.74577 3 8.5L5 8.5ZM8.47633 14.1174C6.37389 12.977 5 10.8721 5 8.5L3 8.5C3 11.6797 4.8433 14.4221 7.52277 15.8754L8.47633 14.1174ZM9.77329 21.124L8.99183 14.8724L7.00727 15.1204L7.78873 21.3721L9.77329 21.124ZM10.7656 22C10.2612 22 9.83584 21.6245 9.77329 21.124L7.78873 21.3721C7.97639 22.8734 9.2526 24 10.7656 24L10.7656 22ZM13.2344 22L10.7656 22L10.7656 24L13.2344 24L13.2344 22Z" :fill="localColor"/>
  </svg>
</template>

<script>
import iconMixin from '@/mixins/icon.mixin';
export default {
  name: 'IconLightbulb',
  mixins: [iconMixin],
};
</script>