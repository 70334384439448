<template>
  <svg 
    fill="none" 
    :enable-background="`new 0 0 ${iconWidth} ${iconHeight}`" 
    :height="iconHeight" 
    :width="iconWidth" 
    :viewBox="`0 0 ${iconWidth} ${iconHeight}`"
    xmlns="http://www.w3.org/2000/svg"
    v-on="events"
  >
    <path 
      d="M5.67969 3L21.6797 19" 
      :stroke="localColor" 
      :stroke-width="stroke" 
      stroke-linecap="round"/>
    <path 
      :stroke="localColor" 
      :stroke-width="stroke" 
      stroke-linecap="round" 
      stroke-linejoin="round"
      d="M22.2679 13.2301C22.4458 12.5884 22.3647 11.8926 22.0246 11.3002C20.3114 8.31636 17.8496 6.09893 15 5.11621M19.6278 16.9998C17.4379 19.2278 14.6784 20.5562 11.6798 20.5562C7.44377 20.5562 3.68497 17.9053 1.33497 13.8123C0.888345 13.0344 0.888342 12.0782 1.33497 11.3003C2.93979 8.50506 5.20165 6.38241 7.82381 5.3173"/>
    <path 
      :stroke="localColor" 
      :stroke-width="stroke"
      d="M14.5892 11.8254C14.748 12.4572 14.6968 13.1235 14.4434 13.7237C14.1899 14.3239 13.748 14.8252 13.1844 15.152C12.6208 15.4787 11.9662 15.6132 11.3194 15.5349C10.6726 15.4567 10.0689 15.17 9.59949 14.7183C9.13007 14.2666 8.82045 13.6743 8.71743 13.031C8.61441 12.3877 8.7236 11.7284 9.02848 11.1527C9.33337 10.5769 9.81738 10.1161 10.4074 9.83981C10.9973 9.56351 11.6612 9.48677 12.2987 9.6212"/>
  </svg>
</template>

<script>
import iconMixin from '@/mixins/icon.mixin';
export default {
  name: 'IconHide',
  mixins: [iconMixin],
};
</script>