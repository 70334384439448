<template>
  <svg 
    :fill="localColor" 
    :enable-background="`new 0 0 ${iconWidth} ${iconHeight}`" 
    :height="iconHeight" 
    :width="iconWidth" 
    :viewBox="`0 0 ${iconWidth} ${iconHeight}`"
    xmlns="http://www.w3.org/2000/svg"
    v-on="events"
  >
    <g>
      <path
        d="m337.83 340.889c5.857 5.858 15.355 5.859 21.213 0l63.676-63.676 63.676 63.676c5.857 5.857 15.355 5.859 21.213 0 5.858-5.858 5.858-15.355 0-21.213l-63.677-63.676 63.676-63.676c5.858-5.858 5.858-15.355 0-21.213-5.857-5.858-15.355-5.858-21.213 0l-63.676 63.676-63.676-63.676c-5.858-5.858-15.356-5.858-21.213 0-5.858 5.858-5.858 15.355 0 21.213l63.676 63.676-63.676 63.676c-5.857 5.858-5.857 15.355.001 21.213z"/>
      <path
        d="m261.797 53.757-168.338 92.671h-78.459c-8.284 0-15 6.716-15 15v189.145c0 8.284 6.716 15 15 15h78.459l168.338 92.671c18.896 10.401 42.013-3.266 42.013-24.83v-354.827c0-21.553-23.108-35.237-42.013-24.83zm-231.797 122.671h52.315v159.145h-52.315zm243.81 254.183-161.495-88.904v-171.414l161.495-88.904z"/>
    </g>
  </svg>
</template>

<script>
import iconMixin from '@/mixins/icon.mixin';
export default {
  name: 'IconVolumeMute',
  mixins: [iconMixin],
};
</script>