<template>
  <div class="container">
    <div class="options">
      <slot name="audio"/>
      <slot name="difficulty"/>
    </div>

    <div class="content" :disabled="lodash.get(sound.statuses, 'checkWord')">
      <UsedWords
        :cells="currentSoundWords"
        :answer="currentSoundAnswerWords"
        :useHelp="selectedHelp"
        :useTips="selectedTips"
        @select="clickToUsedWord"
      />

      <HelpToolbar
        :max="maxHelp"
        :current="currentSoundWordTipsCount"
        :useHelp="selectedHelp"
        :useAuto="selectedAuto"
        :useTips="selectedTips"
        :canChangeTips="user.role === 1"
        @change:help="selectedHelp = !selectedHelp"
        @change:auto="selectedAuto = !selectedAuto"
        @change:tips="selectedTips = !selectedTips"
      />

      <UnusedWords
        :words="unusedWords"
        :focusWord="focusWord"
        :useHelp="selectedHelp"
        @select="clickToUnusedWord"
      />
    </div>
  </div>
</template>


<script>
import axios from 'axios';
import DateFilter from '@/filters/date.filter';

import HelpToolbar from './HelpToolbar.vue';
import UnusedWords from './UnusedWords.vue';
import UsedWords from './UsedWords.vue';

import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  name: 'GamePageSound',

  components: {
    HelpToolbar,
    UnusedWords,
    UsedWords,
  },

  props: {
    sound: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    lodash: _,
    selectedHelp: false,
    selectedAuto: false,
    selectedTips: false,
    focusWordId: null,
    time: {},
  }),

  watch: {
    'sound._id': {
      immediate: true,
      handler() {
        this.launchSound();
        this.time.start = new Date();
        const fragment = _.get(this.sound, 'fragment', {});
        this.setCurrentSoundWords(fragment.shuffle.reduce((acc, value, id) => {
          const tip = _.get(fragment.splitWords, id);
          acc[id] = { id, value, tip };
          return acc;
        }, {}));
      },
    },
    'sound.fragment.tips': {
      deep: true,
      immediate: true,
      handler(tips, oldTips = {}) {
        for (const position in tips) {
          if (oldTips[position] === tips[position]) continue;
          const value = tips[position];
          this.setCurrentSoundAnswerWords({ [position]: { value, valid: true } });
          const from = _.findKey(this.currentSoundWords, { value });
          this.setCurrentSoundWords({ [from]: { valid: true } });
        };
      },
    },
    selectedHelp() {
      this.focusWordId = null;
    },
  },

  computed: {
    ...mapGetters([
      'axiosOptions',
      'maxHelp',
      'currentSoundWordTipsCount',
      'currentSoundAnswerWords',
      'currentSoundWords',
    ]),

    focusWord: ths => ths.currentSoundWords[ths.focusWordId],
    unusedWords() {
      return _.reduce(this.currentSoundWords, (acc, word, wordId) => {
        if (word.valid) return acc;
        acc[wordId] = word;
        return acc;
      }, {});
    },
  },

  methods: {
    ...mapActions([
      'checkWordBySoundId',
      'checkAllWordsBySoundId',
      'getWordBySoundId',
      'useWordTipBySoundId',
    ]),
    ...mapMutations([
      'deleteCurrentSoundAnswerWord',
      'setCurrentSoundAnswerWords',
      'setCurrentSoundWords',
    ]),

    launchSound() {
      const date = DateFilter(new Date(), 'datehour', true, true);
      const options = { sound_id: this.sound._id, date };
      axios.put(`/api/analytics/sound/launch`, options, this.axiosOptions);
    },

    async clickToUsedWord(position) {
      if (_.get(this.sound.statuses, 'checkWord')) return;
      if (this.selectedHelp) return this.useHelp(position);
      if (_.get(this.currentSoundAnswerWords[position], 'valid')) return;
      if (typeof this.focusWordId !== 'number') return;
      const valid = await this.checkWord(position);
      const { value, id } = this.focusWord;
      this.setCurrentSoundAnswerWords({ [position]: { value, valid } });
      const status = valid ? 'success' : 'error';
      this.$metrika.add(
        this.$metrika.consts.GAME_SELECT_WORD, 
        { game: { words: { status } } },
        status,
      );
      this.focusWordId = null;
      if (!valid) return;
      this.setCurrentSoundWords({ [id]: { valid: true } });
      if (_.size(this.currentSoundWords) === _.size(this.currentSoundAnswerWords)) this.checkFinish();
    },

    clickToUnusedWord(word) {
      if (_.get(this.sound.statuses, 'checkWord')) return;
      if (word.id === this.focusWordId) return (this.focusWordId = null);
      this.focusWordId = word.id;
      for (const position in this.currentSoundAnswerWords) {
        const word = this.currentSoundAnswerWords[position];
        if (word.valid) continue;
        this.deleteCurrentSoundAnswerWord(position);
      };
    },

    async useHelp(position) {
      try {
        if (_.get(this.currentSoundAnswerWords, `[${ position }].valid`)) {
          const message = 'Данное слово уже известно!';
          this.$bus.$emit('showNotification', { message, status: 'warning' });
        } else {
          const { tipsLeft } = await this.useWordTipBySoundId({ position });
          const message = `Доступно ${tipsLeft} из ${this.maxHelp} подсказок!`;
          this.$bus.$emit('showNotification', { message, status: 'warning' });
          this.$metrika.add(this.$metrika.consts.GAME_USE_HELP_LAMP, {}, tipsLeft);
        };
      } catch (err) {
        console.error(err);
      } finally {
        this.selectedHelp = false;
      };
    },

    async checkWord(position) {
      const id = this.focusWord.id;
      try {
        this.setCurrentSoundWords({ [id]: { loading: true } });
        const word = this.currentSoundWords[id].value;
        return this.checkWordBySoundId({ position, word });
      } catch(err) {
        console.error(err);
      } finally {
        this.setCurrentSoundWords({ [id]: { loading: false } });
      };
    },

    async checkFinish() {
      try {
        const words = _.map(this.currentSoundAnswerWords, 'value');
        const valid = await this.checkAllWordsBySoundId({ words });
        if (!valid) throw new Error();
        this.time.end = new Date();
        this.$emit('finish', { time: this.time, words });
      } catch(err) {
        const message = 'Во время финальной проверки что-то пошло не так';
        this.$bus.$emit('showNotification', { message, status: 'error' });
        console.error(err);
      };
    },
  },
};
</script>


<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  gap: 20px;

  .options {
    display: grid;
    grid-template-columns: 60px 1fr;
    align-items: center;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    &[disabled] {
      opacity: .75;
      cursor: not-allowed;
    }
  }
}
</style>