<template>
  <GamePlayMain />
</template>

<script>
import GamePlayMain from '@/components/game/play/GamePlayMain';

export default {
  name: 'GamePlay',
  
  components: {
    GamePlayMain,
  },
};
</script>