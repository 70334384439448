import { render, staticRenderFns } from "./GamePageFinish.vue?vue&type=template&id=e0370066&scoped=true&"
import script from "./GamePageFinish.vue?vue&type=script&lang=js&"
export * from "./GamePageFinish.vue?vue&type=script&lang=js&"
import style0 from "./GamePageFinish.vue?vue&type=style&index=0&id=e0370066&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e0370066",
  null
  
)

export default component.exports