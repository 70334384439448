<template>
  <svg 
    fill="none" 
    :enable-background="`new 0 0 ${iconWidth} ${iconHeight}`" 
    :height="iconHeight" 
    :width="iconWidth" 
    :viewBox="`0 0 ${iconWidth} ${iconHeight}`"
    xmlns="http://www.w3.org/2000/svg"
    v-on="events"
  >
    <path 
      :fill="localColor"
      fill-rule="evenodd" 
      clip-rule="evenodd" 
      d="M13.2027 7.57235C13.2027 7.17172 13.6503 6.93379 13.9824 7.15791L20.0468 11.2508C20.3406 11.449 20.3406 11.8814 20.0468 12.0797L13.9824 16.1725C13.6503 16.3967 13.2027 16.1587 13.2027 15.7581V7.57235ZM15.1013 5.50014C13.4409 4.37953 11.2027 5.56918 11.2027 7.57235V9.81433C11.1153 9.73549 11.0209 9.66146 10.9195 9.59301L4.85508 5.50014C3.19468 4.37953 0.956543 5.56918 0.956543 7.57235V15.7581C0.956543 17.7613 3.19469 18.9509 4.85509 17.8303L10.9195 13.7374C11.0209 13.669 11.1153 13.595 11.2027 13.5161V15.7581C11.2027 17.7613 13.4409 18.9509 15.1013 17.8303L21.1657 13.7374C22.6343 12.7463 22.6343 10.5842 21.1657 9.59301L15.1013 5.50014ZM2.95654 7.57235C2.95654 7.17172 3.40417 6.93379 3.73625 7.15791L9.80066 11.2508C10.0944 11.449 10.0944 11.8814 9.80066 12.0797L3.73625 16.1725C3.40417 16.3967 2.95654 16.1587 2.95654 15.7581V7.57235Z"/>
  </svg>
</template>

<script>
import iconMixin from '@/mixins/icon.mixin';
export default {
  name: 'IconForward',
  mixins: [iconMixin],
};
</script>