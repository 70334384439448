<template>
  <div class="words">
    <template v-for="(word, position) of cells">
      <button
        :class="['word', { 
          help: useHelp && !isGuessed(word),
          error: isError(word),
          guessed: isGuessed(word),
        }]"
        @click="$emit('select', +position)"
      >
        <span 
          v-if="word.loading" 
          v-text="'...'"
        />
        <span
          v-else-if="getWord(word).value"
          v-text="getWord(word).value"
        />
        <span 
          v-else-if="useTips && word.tip" 
          v-text="word.tip"
        />
        <span 
          v-else 
          v-text="+position + 1"
        />
      </button>
    </template>
  </div>
</template>


<script>
import _ from 'lodash';

export default {
  name: 'UsedWords',

  props: {
    useTips: Boolean,
    useHelp: Boolean,
    cells: {
      type: Object,
      required: true, 
    },
    answer: {
      type: Object,
      required: true, 
    },
  },

  methods: {
    getWord(word) {
      return _.get(this.answer, word.id, {});
    },
    isError(word) {
      return this.getWord(word).valid === false;
    },
    isGuessed(word) {
      return this.getWord(word).valid === true;
    },
  },
};
</script>


<style lang="scss" scoped>
.words {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 5px 10px;
  .word {
    min-width: 30px;
    height: 30px;
    background-color: #EFF0F6;
    font-weight: 600;
    color: #ccc;
    border-radius: 10px;
    padding: 7px 10px;
    border: 0;
    cursor: pointer;

    &.error {
      color: #E5051F;
      box-shadow: inset 0 0 0 2px #E5051F;
      background-color: #FF75CB;
    }
    &.guessed, &.help {
      color: #008A00;
      box-shadow: inset 0 0 0 2px #008A00;
      background-color: #85E89D;
    }
    &.help {
      opacity: .33;
    }
  }
}
</style>