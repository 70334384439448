<template>
  <div class="actions">
    <div class="actions__body">
      <div class="action__body_item">
        <IconBackward
          :size="24"
          class="back"
          :color="canBackward ? '#FCFCFC' : '#B5C7E1'"
          @click="canBackward && $emit('backward')"
        />
      </div>
      <div class="action__body_item">
        <div class="current_sound">
          <component
            :is="!useHelp ? 'IconView' : 'IconHide'"
            :size="24"
            color="#FCFCFC"
            @click="$emit('help')"
          />
          <div 
            v-if="useHelp"
            class="current_sound_active"
            v-text="soundName"
          />
        </div>
      </div>
      <div class="action__body_item">
        <IconForward
          :size="24"
          class="next"
          :color="canForward ? '#FCFCFC' : '#B5C7E1'"
          @click="canForward && $emit('forward')"
        />
      </div>
    </div>
  </div>
</template>


<script>
import IconHide from '@/components/icons/IconHide.vue';
import IconView from '@/components/icons/IconView.vue';
import IconForward from '@/components/icons/IconForward.vue';
import IconBackward from '@/components/icons/IconBackward.vue';

export default {
  name: 'GameHeader',

  components: {
    IconHide,
    IconView,
    IconForward,
    IconBackward,
  },

  props: {
    canBackward: Boolean,
    canForward: Boolean,
    useHelp: Boolean,
    soundName: [String, Boolean],
  },
};
</script>


<style lang="scss" scoped>
.actions {
  padding-top: 80px;
  padding-bottom: 60px;
  height: 25px;
  &__body {
    display: flex;
    justify-content: space-between;
    position: relative;
    .action__body_item {
      min-width: 25px;
      .next {
        height: 24px;
        width: 24px;
      }
      .back {
        height: 24px;
        width: 24px;
      }
      .current_sound {
        font-size: 16px;
        text-align: center;
        cursor: pointer;
        &_active {
          text-transform: none;
          font-size: 13px;
          padding-top: 5px;
        }
      }
    }
  }
}
</style>