<template>
  <div class="line">
    <div :class="['auto', { active: useTips }]">
      <template v-if="canChangeTips">
        <span @click="$emit('change:tips', !useTips)">Показать ответ</span>
        <button @click="$emit('change:tips', !useTips)">
          <IconGraduationCap size="24"/>
        </button>  
      </template>
    </div>

    <div :class="['help', { limit: reachedLimit, active: useHelp }]">
      <span v-text="text"/>
      <button @click="changeHelp">
        <IconLightbulb size="24"/>
      </button>  
    </div>
  </div>
</template>


<script>
import IconLightbulb from '@/components/icons/IconLightbulb.vue';
import IconGraduationCap from '@/components/icons/IconGraduationCap.vue';

export default {
  name: 'HelpToolbar',

  components: {
    IconLightbulb,
    IconGraduationCap,
  },

  props: {
    canChangeTips: Boolean,
    useTips: Boolean,
    useHelp: Boolean,
    useAuto: Boolean,
    current: Number,
    max: Number,
  },

  computed: {
    reachedLimit: ths => ths.current >= ths.max,
    text: ths => `${ths.current}/${ths.max}`,
  },

  methods: {
    changeHelp() {
      if (this.reachedLimit) {
        this.$metrika.add(
          this.$metrika.consts.GAME_USE_HELP_LAMP,
          { game: { words: { usedHelps: 'limit' } } },
        );
        return this.$bus.$emit('showNotification', {
          message: 'Превышено количество разрешенных подсказок!',
          status: 'danger-game',
        });
      };

      if (this.useHelp) {
        this.$metrika.add(
          this.$metrika.consts.GAME_USE_HELP_LAMP,
          { game: { words: { usedHelps: 'cancel' } } },
        );
        this.$bus.$emit('showNotification', {
          message: 'Режим подсказки отключен!',
          status: 'success',
        });
      } else {
        this.$bus.$emit('showNotification', {
          message: 'Выберите ячейку, которую хотите открыть!',
          status: 'success',
        });
      };
      this.$emit('change:help', !this.useHelp);
    },

    changeAuto() {
      if (this.useAuto) {
        this.$bus.$emit('showNotification', {
          message: 'Режим автоматической подстановки отключен!',
          status: 'success',
        });
      } else {
        this.$bus.$emit('showNotification', {
          message: 'Теперь слова будет подставляться автоматически!',
          status: 'success',
        });
      };
      this.$emit('change:auto', !this.useAuto);
    },
  },
};
</script>


<style lang="scss" scoped>
.line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;

  button {
    border: none;
    padding: 0;
    background: transparent;
  }

  .auto {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 5px;
    &:not(.active) {
      opacity: 0.5;
    }
  }

  .help {
    display: flex;
    align-items: center;
    gap: 5px;
    &.limit, &:not(.active) {
      opacity: 0.5;
    }
  }
}
</style>