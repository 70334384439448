<template>
  <div class="modal-backdrop">
    <div class="modal-level">
      <header class="modal-level__header">
        <slot name="header">
          У вас отлично получается <br> &#129321;
        </slot>
      </header>

      <section class="modal-level__body">
        <slot name="body">
          Хотите поднять уровень сложности и заработать больше баллов?
        </slot>
      </section>

      <footer class="modal-level__footer">

        <button
            type="button"
            class="btn-modal btn-modal__close"
            @click="close"
        >
          Продолжить
        </button>
        <button
            type="button"
            class="btn-modal btn-modal__levelUp"
            @click="levelUp"
        >
          Повыстить уровень
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalLevelUp",
  methods: {
    close() {
      this.$emit('close');
    },
    levelUp() {
      this.$emit('levelUp');
      this.$emit('close');
    },
  },
}
</script>

<style scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.modal-level {
  background: #FFFFFF;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  max-width: 80%;
  display: flex;
  padding: 20px;
  flex-direction: column;
  border-radius: 10px;
}

.modal-level__header,
.modal-level__footer {
  padding: 5px 15px;
  display: flex;
}

.modal-level__header {
  position: relative;
  color: #000;
  font-weight: 600;
  text-align: center;
  font-size: 20px;
  line-height: 1.5;
  justify-content: center;
}

.modal-level__footer {
  display: flex;
  justify-content: center;
}

.modal-level__body {
  position: relative;
  padding: 20px 10px;
  color:#3b304f;
  text-align: center;
  line-height: 1.35;
}

.btn-modal{
  color: white;
  border: 2px solid #4E4B66;
  border-radius: 7px;
  flex-basis: 40%;
  margin: 0 10px;
  padding: 7px;
  cursor: pointer;
  font-size: 14px;
  line-height: 1.3;
}
.btn-modal__close
{
  background-color: #3b304f;
}

.btn-modal__levelUp
{
  background-color: #a996ff;
  border: 2px solid #4E4B66;
}

</style>