<template>
  <div class="words">
    <template v-for="word of words">
      <button 
        v-if="!word.guessed && !word.error"
        :disabled="word.selected"
        :class="['word', {
          loading: word.loading,
          focused: word.id === focusWord.id,
          help: !word.guessed && useHelp,
        }]"
        @click="$emit('select', word)"
        v-text="word.value"
      />
    </template>
  </div>
</template>


<script>
export default {
  name: 'UnusedWord',

  props: {
    useHelp: Boolean,
    words: {
      type: Object,
      required: true,
    },
    focusWord: {
      default: () => ({}),
    },
  },
};
</script>


<style lang="scss" scoped>
.words {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;

  .word {
    border: 2px solid #4E4B66;
    border-radius: 10px;
    cursor: pointer;
    background-color: #EFF0F6;
    color: #4E4B66;
    padding: 6px 10px;
    font-size: inherit;

    &.focused {
      background-color: #baa2e7;
      transition: 0.3s all;
    }

    &[disabled] {
      opacity: .5;
      cursor: not-allowed;
    }
  } 
}
</style>