<template>
  <div class="volume" @click="played = !played">
    <IconVolumeUp v-if="played"/>
    <IconVolumeMute v-else/>
  </div>
</template>


<script>
import _ from 'lodash';
import IconVolumeUp from '@/components/icons/IconVolumeUp.vue';
import IconVolumeMute from '@/components/icons/IconVolumeMute.vue';

export default {
  name: 'GameHeaderAudio',

  components: {
    IconVolumeUp,
    IconVolumeMute,
  },

  props: {
    source: [String, null],
    canPlay: Boolean,
  },

  data: () => ({
    played: false,
    audio: null,
  }),

  watch: {
    played(played) {
      localStorage.setItem('soundPlaying', played);
      played ? this.audio.play() : this.audio.pause();
      this.$bus.$emit('audioStatus', played);
    },
    source: {
      immediate: true,
      handler(source) {
        this.destroy();
        if (!this.source || !this.canPlay) return;
        this.audio = new Audio(this.source);
        this.audio.volume = 0.05;
        this.audio.loop = true;
        // this.audio.onended = this.onEnded.bind(this);
        if (!this.played) return;
        this.audio.play();
      },
    },
  },

  beforeDestroy() {
    this.destroy();
  },

  methods: {
    destroy() {
      _.invoke(this.audio, 'pause');
      this.audio = null;
    },

    onEnded() {
      // this.currentLibrary = this.library.find(el => !el.played);
      // if (!this.currentLibrary) {
      //   this.continueAudio(status);
      // } else {
      //   this.currentLibrary.played = true;
      //   this.backSounds(status);
      // }
    },
  },
};
</script>


<style lang="scss" scoped>
.volume {
  cursor: pointer;
  & > svg {
    width: 25px;
    height: 23px;
  }
}
</style>