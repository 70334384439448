<template>
  <svg 
    fill="none" 
    :enable-background="`new 0 0 ${iconWidth} ${iconHeight}`" 
    :height="iconHeight" 
    :width="iconWidth" 
    :viewBox="`0 0 ${iconWidth} ${iconHeight}`"
    xmlns="http://www.w3.org/2000/svg"
    v-on="events"
  >
    <path 
      :fill="localColor"
      fill-rule="evenodd" 
      clip-rule="evenodd" 
      d="M20.2378 7.57235C20.2378 7.17172 19.7902 6.93379 19.4581 7.15791L13.3937 11.2508C13.1 11.449 13.1 11.8814 13.3937 12.0797L19.4581 16.1725C19.7902 16.3967 20.2378 16.1587 20.2378 15.7581V7.57235ZM18.3393 5.50014C19.9997 4.37953 22.2378 5.56918 22.2378 7.57235V15.7581C22.2378 17.7613 19.9996 18.9509 18.3392 17.8303L12.2748 13.7374C12.1734 13.669 12.079 13.595 11.9916 13.5161V15.7581C11.9916 17.7613 9.75348 18.9509 8.09308 17.8303L2.02868 13.7374C0.560062 12.7463 0.560068 10.5842 2.02868 9.59301L8.09308 5.50014C9.75348 4.37953 11.9916 5.56918 11.9916 7.57236V9.81432C12.079 9.73548 12.1734 9.66146 12.2748 9.59301L18.3393 5.50014ZM9.99162 7.57236C9.99162 7.17172 9.544 6.93379 9.21191 7.15791L3.14751 11.2508C2.85379 11.449 2.85379 11.8814 3.14751 12.0797L9.21191 16.1725C9.54399 16.3967 9.99162 16.1587 9.99162 15.7581V7.57236Z"/>
  </svg>
</template>

<script>
import iconMixin from '@/mixins/icon.mixin';
export default {
  name: 'IconBackward',
  mixins: [iconMixin],
};
</script>