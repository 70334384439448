<template>
  <svg width="28" height="28" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M32.2959 16.0791L18.7959 2.57906C18.6915 2.47446 18.5674 2.39149 18.4308 2.33487C18.2942 2.27826 18.1478 2.24911 18 2.24911C17.8522 2.24911 17.7058 2.27826 17.5692 2.33487C17.4326 2.39149 17.3085 2.47446 17.2041 2.57906L3.70406 16.0791C3.54655 16.2364 3.43926 16.4369 3.39578 16.6553C3.3523 16.8736 3.37458 17.1 3.4598 17.3056C3.54502 17.5113 3.68935 17.6871 3.87452 17.8107C4.05969 17.9343 4.27737 18.0002 4.5 18H10.125V29.25C10.125 29.8467 10.3621 30.419 10.784 30.841C11.206 31.2629 11.7783 31.5 12.375 31.5H23.625C24.2217 31.5 24.794 31.2629 25.216 30.841C25.6379 30.419 25.875 29.8467 25.875 29.25V18H31.5C31.7226 18.0002 31.9403 17.9343 32.1255 17.8107C32.3106 17.6871 32.455 17.5113 32.5402 17.3056C32.6254 17.1 32.6477 16.8736 32.6042 16.6553C32.5607 16.4369 32.4535 16.2364 32.2959 16.0791ZM24.75 15.75C24.4516 15.75 24.1655 15.8685 23.9545 16.0795C23.7435 16.2905 23.625 16.5766 23.625 16.875V29.25H12.375V16.875C12.375 16.5766 12.2565 16.2905 12.0455 16.0795C11.8345 15.8685 11.5484 15.75 11.25 15.75H7.21547L18 4.96547L28.7845 15.75H24.75Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: "IconLevelUp"
}
</script>

<style scoped>

</style>