<template>
  <svg 
    :fill="localColor" 
    :enable-background="`new 0 0 ${iconWidth} ${iconHeight}`" 
    :height="iconHeight" 
    :width="iconWidth" 
    :viewBox="`0 0 ${iconWidth} ${iconHeight}`"
    xmlns="http://www.w3.org/2000/svg"
    v-on="events"
  >
    <g>
      <path
        d="m15 365.573h78.459l168.338 92.671c18.896 10.401 42.013-3.266 42.013-24.83v-354.827c0-21.553-23.107-35.237-42.013-24.83l-168.338 92.671h-78.459c-8.284 0-15 6.716-15 15v189.145c0 8.284 6.716 15 15 15zm258.81-284.184v349.222l-161.495-88.904v-171.414zm-243.81 95.039h52.315v159.145h-52.315z"/>
      <path
        d="m424.962 97.101c-5.857 5.858-5.856 15.356.003 21.213 36.78 36.769 57.035 85.666 57.035 137.682 0 52.015-20.255 100.912-57.035 137.682-9.38 9.377-2.771 25.613 10.605 25.613 4.225 0 8.042-1.748 10.768-4.56 42.345-42.419 65.662-98.782 65.662-158.735 0-60.031-23.377-116.461-65.825-158.898-5.858-5.858-15.356-5.856-21.213.003z"/>
      <path
        d="m382.542 351.266c-5.857 5.859-5.855 15.356.004 21.213 5.857 5.856 15.356 5.856 21.213-.004 31.109-31.12 48.241-72.485 48.241-116.475s-17.132-85.354-48.242-116.475c-5.857-5.859-15.354-5.861-21.213-.004s-5.861 15.354-.004 21.213c25.446 25.455 39.459 59.288 39.459 95.266s-14.013 69.811-39.458 95.266z"/>
      <path
        d="m340.11 330.044c5.856 5.859 15.353 5.863 21.213.006 19.782-19.77 30.677-46.068 30.677-74.05s-10.895-54.28-30.676-74.05c-5.86-5.856-15.357-5.853-21.213.006-5.856 5.86-5.853 15.357.006 21.213 14.112 14.104 21.883 32.867 21.883 52.831s-7.771 38.727-21.883 52.83c-5.86 5.856-5.863 15.354-.007 21.214z"/>
    </g>
  </svg>
</template>

<script>
import iconMixin from '@/mixins/icon.mixin';
export default {
  name: 'IconVolumeUp',
  mixins: [iconMixin],
};
</script>