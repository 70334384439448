<template>
  <div class="authors">
    <h3>Угадайте автора текста</h3>
    <div class="authors__main">
      <button 
        v-for="author of authors" 
        :key="author._id"
        :class="{ answer: author.answer }"
        class="authors__main_item"
        @click="$emit('finish', author._id)" 
        v-text="author.name"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'GamePageAuthor',

  props: {
    sound: {
      type: Object,
      required: true,
    },
    authors: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.authors {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 24px;
  gap: 20px;

  &__main {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    gap: 16px;

    &_item {
      background-color: #aa8ae9;
      border: 2px solid #fff;
      border-radius: 10px;
      color: #fff;
      width: 80%;
      font-size: 16px;
      font-weight: 500;
      padding: 20px 0;
      cursor: pointer;
      &.answer {
        border: 2px dashed #4E4B66;
      }
    }
  }
}
</style>